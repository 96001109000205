exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bdtr-js": () => import("./../../../src/pages/bdtr.js" /* webpackChunkName: "component---src-pages-bdtr-js" */),
  "component---src-pages-dersler-js": () => import("./../../../src/pages/dersler.js" /* webpackChunkName: "component---src-pages-dersler-js" */),
  "component---src-pages-dinamik-js": () => import("./../../../src/pages/dinamik.js" /* webpackChunkName: "component---src-pages-dinamik-js" */),
  "component---src-pages-fotograf-js": () => import("./../../../src/pages/fotograf.js" /* webpackChunkName: "component---src-pages-fotograf-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sonlueltemel-js": () => import("./../../../src/pages/sonlueltemel.js" /* webpackChunkName: "component---src-pages-sonlueltemel-js" */),
  "component---src-pages-statik-js": () => import("./../../../src/pages/statik.js" /* webpackChunkName: "component---src-pages-statik-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-yayinlar-js": () => import("./../../../src/pages/yayinlar.js" /* webpackChunkName: "component---src-pages-yayinlar-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

